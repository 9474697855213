.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
  width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: calc(90px - 50px);
}

.userlistss tbody tr td {
  padding: 0px 12px;
}

.form-control:focus {
  color: #fff !important;
  border: 1px solid rgb(147 130 177 / 61%) !important;
}

/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
  cursor: pointer;
}

select option {
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer !important;
  color: #fff !important;
}

.asrt-pagination li {
  cursor: pointer;
}

.passwordinput .form-control {
  background-color: #191c24;
}

.passwordinput .btn-outline-primary {
  /* color: #6e7ee5; */
  color: #16ebc3;
  border-color: #494a51 !important;
  /* border: none !important; */
  padding: 10px 10px;
  background-color: transparent !important;
  box-shadow: none !important;
}

.passwordinput .btn-outline-primary:hover {
  background-color: transparent !important;
}

.footer a {
  /* color: #886abe !important; */
  color: #16ebc3 !important;
}

.asrt-page-length .input-group-addon {
  background: unset !important;
}

.asrt-page-length select {
  color: #fff !important;
}

.input-group-prepend span {
  color: #fff !important;
}

.asrt-table-foot {
  margin-top: 2%;
}

.page-item.disabled .page-link:hover {
  background-color: #886abe !important;
  color: #fff !important;
}

.as-react-table .pagination li:hover a {
  /* background-color: #886abe !important; */
  background-color: #16ebc3 !important;
  color: black !important;
}

.page-link {
  background-color: #00000000 !important;
  border: 1px solid #2c2e33;
  color: white !important;
}

.page-link input {
  background: unset !important;
  color: #fff !important;
}

.allbtn {
  /* background: #886abe;
  border: 1px solid #886abe; */
  background-color: #16ebc3 !important;
  padding: 10px 16px;
  color: #000;
  border: none !important;
}
.allbtn:active {
  background-color: #16ebc3 !important;
}
.table thead th {
  line-height: 21px;
}

.allbtnlogout {
  background: red;
  border: 1px solid red;
  padding: 6px 14px;
  color: #fff;
  border-radius: 6px;
}

.allbtnlogout:hover {
  background: transparent;
  border: 1px solid red;
  color: red;
}

.allbtns {
  padding: 8px 16px !important;
}

.cmn_close_btn {
  background-color: #2a3038 !important;
  color: white;
  border: 1px solid #2a3038;
  outline: none;
  box-shadow: none !important;
  padding: 10px 16px;
}
.cmn_close_btn:hover {
  background-color: transparent !important;
  border: 1px solid #2a3038;
  color: white !important;
}
.css-yt9ioa-option {
  color: black !important;
  /* background-color: #886abe !important; */
}

.css-1n7v3ny-option {
  /* background-color: #886abe !important; */
}

.NFT.IDT {
  width: 580px !important;
  max-width: 580px !important;
  word-wrap: break-word !important;
}

.NFT.IDT p {
  line-height: normal;
}

.css-6c09z3-option {
  color: #000 !important;
}

.css-1gj9k8-option {
  background-color: unset !important;
  color: #000 !important;
}

.css-zjsb6u-option {
  background-color: #886abe !important;
}

.css-1n7v3ny-option {
  background-color: unset !important;
  color: black !important;
  /* background-color: transparent !important;
   color: black !important; */
}

.css-1pahdxg-control:hover {
  border-color: white !important;
}

.css-9gakcf-option {
  background-color: #886abe !important;
}

/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover {
  /* background: transparent; */
  /* border: 1px solid #886abe; */
  /* color: #886abe !important; */
  background-image: linear-gradient(to right, #1042f3, #16ebc3) !important;
  color: white;
  border: none !important;
}

.icon.icon-box-success {
  background: rgb(130 101 182 / 24%) !important;
}

.icon .icon-item {
  color: #9768ec;
}

.ReactModal__Overlay {
  background-color: #00000091 !important;
}

.ReactModal__Content {
  border: 1px solid #808080a8 !important;
  background: rgb(11 12 15) !important;
  border-radius: 16px !important;
}

.ReactModal__Content h2 {
  /* color: rgb(136 106 190) !important; */
  color: white !important;
}

.closebtn {
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: 19px;
  font-weight: 500;
}

.closebtn:hover {
  color: #886abe;
}

.table-responsive {
  padding-bottom: 2%;
  overflow-x: auto !important;
}

/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar {
  width: 8px !important;
  height: 9px !important;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #886abe;
}

.faq .as-react-table {
  padding-bottom: 2%;
  overflow-x: auto !important;
}

.faq .as-react-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.faq .as-react-table::-webkit-scrollbar {
  width: 8px !important;
  height: 9px !important;
  background-color: #f5f5f5;
}

.faq .as-react-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #886abe;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}

.dash-token {
  /* color: #886abe !important; */
  /* color: #16ebc3 !important; */
  color: white !important;
}

.editservice {
  font-size: 15px;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
  white-space: normal;
  line-height: 20px;
}

/* th.sortable {
    color: #886abe !important;
    font-weight: 600 !important;
} */

@media only screen and (max-width: 767px) {
  .table-responsive::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #f5f5f5;
  }

  .faq .as-react-table::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #f5f5f5;
  }

  .table-responsive {
    padding-bottom: 8%;
  }

  .faq .as-react-table {
    padding-bottom: 8%;
  }

  .pagination {
    justify-content: unset !important;
  }

  .pagination li {
    font-size: 11px !important;
    margin-top: 10px;
  }

  .table_filter input {
    margin-top: 14px;
  }

  .asrt-page-length .input-group-text {
    font-size: 11px;
  }

  .asrt-page-length select.form-control {
    padding: 0.4375rem 0.45rem !important;
    width: 53px !important;
    font-size: 12px !important;
  }

  .asrt-table-head .text-right {
    text-align: left !important;
  }

  .asrt-table-foot > div:first-child {
    font-size: 12px;
    margin-top: 6px;
  }
}

.navbar.loginnavbar {
  left: 0px !important;
}

.btn-primary:not(.btn-light):not(.btn-secondary):focus,
.custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after,
.btn-primary:not(.btn-light):not(.btn-secondary):active,
.custom-file
  .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
  /* background: #886abe !important; */
  /* border-color: #886abe !important; */
  color: #fff !important;
}

.referralpages .btnsec {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.referralpages .add_btnsec {
  margin-left: 15px;
  margin-top: 10px;
}

.referralpages .allbtn {
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .referralpages .add_btnsec {
    margin-left: 12px;
  }

  .referralpages .btnsec {
    margin-bottom: 15px;
  }
}

/* 09-01-24 sakthi */

@media (max-width: 450px) {
  .gallery_img_sec .col {
    max-width: 100% !important;
  }

  .react-datetime-picker__inputGroup {
    font-size: 13px !important;
  }

  .react-datetime-picker__button svg {
    width: 14px;
  }

  .react-datetime-picker__calendar-button {
    display: none !important;
  }

  .gallery_img_sec {
    justify-content: center;
  }
}

@media (max-width: 350px) {
  .custom_addnft_header {
    flex-wrap: wrap !important;
  }
}

.gallery_img_sec {
  display: flex;
  align-items: center;
}
.cus-galleryimg-icon {
  position: relative;
}
.cus-galleryimg-icon .delete {
  background-color: #16ebc3 !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.cus-galleryimg-icon .delete:hover {
  background-image: linear-gradient(to right, #1042f3, #16ebc3) !important;
}

.cus-galleryimg-icon .delete .fa-trash {
  font-size: 12px;
}
.galley_images {
  /* display: flex;
  gap: 30px;
  flex-wrap: wrap; */
  /* width: 250px; */
  width: 100%;
  height: 250px;
  object-fit: contain;
  cursor: pointer;
}

.galley_images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom_time_date_picker {
  width: 100%;
}

.custom_time_date_picker .react-datetime-picker__wrapper {
  border: 1px solid #2c2e33;
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0 0.6875rem;
  background-color: #2a3038;
  border-radius: 2px;
  color: #ffffff;
}

/* .custom_time_date_picker .react-datetime-picker__wrapper svg:hover {
  color: #886abe !important;
} */
.custom_time_date_picker
  .react-datetime-picker__button:enabled:hover
  .react-datetime-picker__button__icon,
.custom_time_date_picker
  .react-datetime-picker__button:enabled:focus
  .react-datetime-picker__button__icon {
  stroke: #886abe !important;
}

.custom_time_date_picker .react-calendar__tile--active {
  background-color: #886abe;
}

.custom_time_date_picker .react-calendar {
  background-color: #2a3038 !important;
  border-color: #5c6270 !important;
  color: white;
}

.custom_time_date_picker .react-calendar__month-view__days__day--weekend {
  color: #6c7293 !important;
}

.custom_time_date_picker .react-calendar__month-view__days__day--weekend:hover {
  color: white !important;
}

.custom_time_date_picker .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #886abe !important;
}

.custom_time_date_picker .react-calendar__navigation button {
  color: white;
}

.custom_time_date_picker .react-calendar__navigation button:enabled:hover,
.custom_time_date_picker .react-calendar__navigation button:enabled:focus {
  /* color: black; */
  background-color: #886abe !important;
}

.custom_time_date_picker .react-calendar button {
  color: white;
}

.custom_time_date_picker .react-calendar__tile:enabled:hover,
.custom_time_date_picker .react-calendar__tile:enabled:focus {
  background-color: #886abe !important;
}

.custom_time_date_picker .react-datetime-picker__clock {
  background-color: #2a3038 !important;
  border-color: #5c6270 !important;
  color: white;
}

.custom_time_date_picker .react-calendar__tile--now {
  background-color: #17937c !important;
}

.react-datetime-picker__inputGroup__input option {
  background-color: #2a3038;
}

.react-datetime-picker__inputGroup__input option:hover {
  background-color: #2a3038 !important;
}

.project_duration_select .css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.project_duration_select .css-tlfecz-indicatorContainer {
  color: #191c24 !important;
}

.project_duration_select .css-aa3fwl-ValueContainer {
  height: 38px !important;
}

.css-1q101cb-option:active {
  background-color: #191c24 !important;
  color: white !important;
}

.css-t02mdx-option:active {
  background-color: #191c24 !important;
  color: white !important;
}

.css-t02mdx-option {
  background-color: #191c24 !important;
  color: white !important;
}

.css-1jga40i-option:active {
  background-color: #191c24 !important;
  color: white !important;
}

.nft_custom_th {
  min-width: 150px;
}

.form-group label {
  line-height: 1.3 !important;
}
.form-group label::first-letter {
  text-transform: uppercase;
}
.project_duration_select {
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  /* margin-left: 12px; */
  /* margin-bottom: 20px; */
  border-radius: 5px;
  cursor: pointer;
}

.upload-btn-wrapper .btn {
  background-color: #2a3038;
  padding: 10px 16px;
  color: #6c7290;
  border: none !important;
  cursor: pointer;
  /* z-index: 2; */
  /* position: relative; */
}

/* .upload-btn-wrapper .btn:hover {
  background-image: linear-gradient(to right, #1042f3, #16ebc3) !important;
  color: white;
  border: none !important;
} */

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* .custom_time_date_picker .select-selected {
    background: violet;
} */
.custom_container_scroll {
  height: 100% !important;
  min-height: 100vh;
}

/* .sidebar {
  background: #151515 !important;
}
.sidebar .sidebar-brand-wrapper {
  background: #151515 !important;
}
.navbar {
  background: #151515 !important;
}
.card {
  background: #1d1d1d4f !important;
  background: #151515 !important;
} */

th.sortable {
  color: white !important;
}

.table {
  color: white !important;
  /* font-family: Helonika; */
}

select option:hover {
  background-color: yellow !important;
}

/* #6b6b6b */
.form-control:disabled,
.form-control[readonly] {
  background-color: #2a3038 !important;
  color: white !important;
}

.as-react-table .pagination li:hover a input {
  color: black !important;
}

.sidebar .nav .menu-items:nth-child(5n + 3) .nav-link .menu-icon i {
  color: #16ebc3;
}

.error_msg {
  color: red !important;
}

.form-control.form-control-lg {
  padding: 0.625rem 0.6875rem !important;
}

.Toastify__toast-theme--light {
  background-color: #191c24 !important;
  color: white !important;
  border: 1px solid #4f2a2a;
}

.passwordinput .form-control {
  background-color: transparent !important;
}

.content-wrapper {
  background-color: #050504 !important;
}

.content-wrapper .login_sec {
  width: 100%;
  height: 100%;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #050504 !important;
}

.page-item.disabled .page-link {
  /* color: #9382b1 !important; */
  color: white !important;
  pointer-events: none;
  cursor: auto;
  background-color: #fff0 !important;
  border-color: #2c2e33 !important;
}

::-webkit-scrollbar-thumb {
  background: #16ebc3 !important;
}

/* 1px solid #2c2e33 */

.gallery_modal .gallery_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery_modal .modal-content {
  border-radius: 15px;
}

.gallery_modal .close {
  color: white !important;
  position: relative;
  right: 10px;
}

.gallery_modal .gallery_video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery_modal .gallery_video iframe {
  border: none !important;
}

/* .gallery_modal .modal-header {
  border-bottom: none !important;
}
.gallery_modal .modal-footer {
  border-top: none !important;
} */

.gallery_modal .modal-content {
  background-color: #191c24 !important;
}

.blur_thumbnailer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: #8d8d8d30; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.playBtn_fitter {
  height: -moz-fit-content;
  /* height: fit-content; */
  width: -moz-fit-content;
  /* width: fit-content; */
}

.blur_thumbnailer svg {
  /* color: */
  font-size: 30px;
  font-weight: 500;
  /* background-image: -webkit-linear-gradient(45deg, #1042f3, #16ebc3); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-circle-play {
  color: #16ebc3;
  font-size: 30px;
  font-weight: 500;
  background-image: -webkit-linear-gradient(45deg, #1042f3, #16ebc3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Font Awesome 5 Free";
}

.fa-circle-play:before,
.fa-play-circle:before {
  content: "\f144";
}

.galley_images video {
  /* min-height: 150px;
  max-height: 150px; */
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
}

.galley_images img {
  /* min-height: 150px;
  max-height: 150px; */
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.galley_images .position-relative {
  height: 100%;
}
.exporting_btns {
  background-color: #0071e3;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.exporting_btns:hover {
  outline: 1px solid #0071e3;
  color: #0071e3;
  background-color: transparent;
}

@media (max-width: 380px) {
  .galley_images {
    width: 185px !important;
    height: 185px !important;
  }
  .whichlist_img_sec {
    height: 185px !important;
  }
  .whitemodal_img_sec {
    width: 210px !important;
    height: 170px !important;
  }
}

@media (min-width: 1400px) {
  .whitelist_card_custom {
    width: 22rem;
  }
}
.whitelist_card_custom {
  border-radius: 0.5rem !important  ;
  background-color: #2a3038;
}

.wallet_details .modal-header .close {
  color: white !important;
}

.wallet_details .modal-header {
  border-bottom: none !important;
  /* padding-bottom: 0px !important; */
}
.wallet_details .modal-footer {
  border-top: none !important;
  padding-top: 0px !important;
}

.wallet_details .modal-content {
  border-radius: 0.5rem !important;
}

.wallet_details .body_sec .va{
  color: #7a7c7e;
}
.whitelist_img_sec {
  width: 100%;
  height: 250px;
}
.whitelist_img_sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.whitemodal_img_sec {
  width: 250px;
  height: 220px;
}
.whitemodal_img_sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.white_modal {
  border-radius: 0.5rem !important;
  background-color: #2a3038 !important;
}

@media(max-width:479px){
  .galley_images {
    height: 150px;
  }
}
@media (min-width:480px) and (max-width:767px){
  .galley_images {
    height: 170px;
  }
}